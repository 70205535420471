import AbiturientBakalavrCom from '../../../components/AbiturientBakalavrCom'
import React from 'react'

function Bakalavryat() {
  return (
    <div>
      <AbiturientBakalavrCom />
    </div>
  )
}

export default Bakalavryat;