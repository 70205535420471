import React from "react";
import MagistrFanDasturlariCom from "../../../../components/MagistrFanDasturlariCom";

function MagistrFanDasturlari() {
  return (
    <div>
      <MagistrFanDasturlariCom/>
    </div>
  );
}

export default MagistrFanDasturlari;
