import React from 'react'
import InstitutTuzilmasiCom from '../../components/InstitutTuzilmasiCom'

const InstitutTuzilmasi = () => {
  return (
    <div>
        <InstitutTuzilmasiCom />
    </div>
  )
}

export default InstitutTuzilmasi