import React from 'react'
import FanKatalogiCom from '../../../../components/FanKatalogiCom'

function FanKatalogi() {
  return (
    <div>
        <FanKatalogiCom/>
    </div>
  )
}

export default FanKatalogi