import React from 'react';
import VirQabulCom from '../../components/VirQabul';

const VirQabulxonaPage = () => {
  return (
    <div>
        <VirQabulCom />
    </div>
  )
}

export default VirQabulxonaPage;