import React from 'react'
import VakansiyalarCom from "../../components/VakansiyalarCom"

const Vakansiyalar = () => {
  return (
    <div>
        <VakansiyalarCom />
    </div>
  )
}

export default Vakansiyalar