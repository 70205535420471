import React from 'react'
import InstitutKengashiCom from '../../components/InstitutKengashiCom'

const InstitutKengashi = () => {
  return (
    <div>
        <InstitutKengashiCom />
    </div>
  )
}

export default InstitutKengashi