import React from "react";
import OtishBallariCom from "../../../../components/OtishBallariCom";

function OtishBallari() {

  return (
    <div>
        <OtishBallariCom />
    </div>
);
}

export default OtishBallari;