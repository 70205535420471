import React from "react";
import RektoratCom from "../../../components/RektoratCom";

const Rektorat = () => {
  return (
    <div>
      <RektoratCom />
    </div>
  );
};

export default Rektorat;
