import React from "react";
import Bachelor from "../../../components/Bachelor";

function Bakalavriyat() {
  return (
    <div>
      <Bachelor/>
    </div>
  );
}

export default Bakalavriyat;
