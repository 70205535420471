import React from 'react'
import InstitutHaqidaCom from '../../components/InstitutHaqidaCom'
import InstitutMalumotlariCom from '../../components/InstitutMalumotlariCom'

const InstitutHaqida = () => {
  return (
    <div>
        <InstitutHaqidaCom />
        <InstitutMalumotlariCom/>
    </div>
  )
}

export default InstitutHaqida;