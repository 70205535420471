import React from 'react'
import NewsCard from '../../components/NewsCard'

const New = () => {
    return (
        <div>
            <NewsCard />
        </div>
    )
}

export default New;