import React from 'react'
import NewsPageCom from '../../components/NewsPageCom'

const News = () => {
    return (
        <div>
            <NewsPageCom />
        </div>
    )
}

export default News