import React from 'react'
import MadMarFaoliyatCom from '../../../components/MadMarFaoliyatCom'

function MadMarFaolyat() {
    return (
        <div>
            <MadMarFaoliyatCom />
        </div>
    )
}

export default MadMarFaolyat