import React from 'react'
import AbiturientMeyoriyCom from "../../../components/AbiturientMeyoriyCom"

function MeyyoriyHuquqiyHuj() {
  return (
    <div>
      <AbiturientMeyoriyCom />
    </div>
  )
}

export default MeyyoriyHuquqiyHuj;