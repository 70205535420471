import React from 'react'
import QabulCom from '../../components/QabulCom'

const Qabul = () => {
  return (
    <div>
        <QabulCom />
    </div>
  )
}

export default Qabul