import React from 'react'
import AbiturientMagistrCom from "../../../components/AbiturientMagistrCom"

function Magistratura() {
  return (
    <div>
      <AbiturientMagistrCom />
    </div>
  )
}

export default Magistratura;