import React from 'react'
import OnlineKuzatishCom from '../../components/OnlineKuzatishCom'

const OnlineKuzatish = () => {
  return (
    <div>
        <OnlineKuzatishCom />
    </div>
  )
}

export default OnlineKuzatish