import React from 'react'
import ElonBatafsilCom from '../../components/ElonBatafsilCom'

function ElonBatafsil() {
  return (
    <div>
        <ElonBatafsilCom/>
    </div>
  )
}

export default ElonBatafsil