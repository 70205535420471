import React from 'react'
import AkademikLitseyCom from '../../../components/AkademikLitseyCom'

function AkademikLitsey() {
    return (
        <div>
            <AkademikLitseyCom />
        </div>
    )
}

export default AkademikLitsey